import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { updateConfig } from "../../../../Store/Slices/Expertise/expertiseSlice";
import {
  getCurrencyData,
  getUserSearchData,
  getViewData,
  updateConfig,
} from "../../../../../store/slices/OrderCreate/orderCreateSlice";
import { createProductDetails, updateProductDetails } from "./api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useEffect } from "react";

const useProductDetails = ({ hideStep }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, orderBasicData, orderId } =
    useSelector((state) => state.orderCreate);
  const orderCreateState = useSelector((state) => state.orderCreate);
  const { basicData } = useSelector((state) => state.global);
  const validationSchema = Yup.object({
    user_id: Yup.object().required("*Please select User"),
    branch_id: Yup.string().required("*Please select branch"),
    type: Yup.string().required("*Type is required"),
    order_details: Yup.array().of(
      Yup.object().shape({
        currency_code: Yup.string().required("*Please select currency"),
        product_id: Yup.string().required("*Please select product"),
        quantity: Yup.string().required("*Quantity is required"),
        unit_amount: Yup.string(),
      })
    ),
    total_amount: Yup.string(),
    citizen_type: Yup.string().required("*Citizenship is required"),
  });
  // const {
  //   data: userList = {},
  //   isLoading,
  //   isFetching,
  //   refetch,
  // } = useGetUserDataQuery({
  //   search: inputSearch,
  // });
  const formik = useFormik({
    initialValues: {
      user_id: itemData?.user_id ?? "",
      type: itemData?.type ?? 1,
      branch_id: itemData?.branch_id ?? "",
      order_details:
        itemData?.product_details?.length > 0
          ? itemData?.product_details?.map((event) => ({
              currency_code: event?.currency?.currency_code ?? "",
              product_id: event?.product?.id ?? "",
              quantity: event?.product?.quantity ?? "",
              unit_amount: event?.product?.amount ?? "",
            }))
          : [
              {
                currency_code: "",
                product_id: "",
                quantity: "",
                unit_amount: "",
              },
            ],
      total_amount: itemData?.forex_amount ?? "",
      citizen_type: itemData?.citizen_type ?? 1,
    },

    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (isEdit || orderCreateState?.EditCreate.productDetails) {
        let data = {
          order_id: orderId,
          user_id: values.user_id?._id,
          type: values?.type,
          branch_id: values?.branch_id,
          order_details: values.order_details,
          total_amount: values?.total_amount,
          citizen_type: values?.citizen_type,
          handling_charge: itemData?.handling_charge,
          gst: (itemData?.gst?.CGST ?? 0) + (itemData?.gst?.SGST ?? 0),
          tcs: itemData?.tcs,
          product_declaration: 1,
        };
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "order_details") {
            data.order_details.forEach((item, index) => {
              formData.append(
                `order_details[${index}][currency_code]`,
                item?.currency_code
              );
              formData.append(
                `order_details[${index}][product_id]`,
                item?.product_id
              );
              formData.append(
                `order_details[${index}][quantity]`,
                item?.quantity
              );
              formData.append(
                `order_details[${index}][unit_amount]`,
                item?.unit_amount
              );
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        formData.append("_method", "PUT");
        updateProductDetails(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Product details have been added");
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = !hideStep
                  ? "Travel Details"
                  : "Delivery Details";
                state.orderId = response?.data?.data?._id;
                state.EditCreate.productDetails = true;
                state.EditCreate.travelDetails =
                  orderCreateState?.EditCreate.travelDetails;
                state.EditCreate.deliveryDetails =
                  orderCreateState?.EditCreate.deliveryDetails;
                state.EditCreate.documentDetails =
                  orderCreateState?.EditCreate.documentDetails;
              })
            );
            setSubmitting(false);
            dispatch(getViewData({ id: orderId }));
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.data;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      } else {
        let data = {
          user_id: values.user_id?._id,
          type: values?.type,
          branch_id: values?.branch_id,
          order_details: values.order_details,
          total_amount: values?.total_amount,
          citizen_type: values?.citizen_type,
        };
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "order_details") {
            data.order_details.forEach((item, index) => {
              formData.append(
                `order_details[${index}][currency_code]`,
                item?.currency_code
              );
              formData.append(
                `order_details[${index}][product_id]`,
                item?.product_id
              );
              formData.append(
                `order_details[${index}][quantity]`,
                item?.quantity
              );
              formData.append(
                `order_details[${index}][unit_amount]`,
                item?.unit_amount
              );
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        createProductDetails(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Product details have been added.");
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = !hideStep
                  ? "Travel Details"
                  : "Delivery Details";
                state.orderId = response?.data?.data?._id;
                state.EditCreate.productDetails = true;
                state.EditCreate.travelDetails =
                  orderCreateState?.EditCreate.travelDetails;
                state.EditCreate.deliveryDetails =
                  orderCreateState?.EditCreate.deliveryDetails;
                state.EditCreate.documentDetails =
                  orderCreateState?.EditCreate.documentDetails;
              })
            );
            setSubmitting(false);
            dispatch(getViewData({ id: response?.data?.data?._id }));
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.data;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    let totalAmount = 0;
    formik.values.order_details.forEach((item) => {
      totalAmount += item.unit_amount;
    });
    formik.setFieldValue(`total_amount`, totalAmount ?? "");
  }, [formik?.values?.order_details]);
  
  useEffect(() => {
    if (itemData?.branch_id) {
      dispatch(
        getCurrencyData({
          type: formik?.values?.type,
          branch_id: itemData?.branch_id,
        })
      );
    }
  }, [itemData]);
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleInputSearch = (value) => {
    dispatch(
      updateConfig((state) => {
        state.inputSearch = value;
      })
    );
  };
  const handleBranchChange = (value) => {
    formik.setFieldValue("branch_id", value ?? "");
    dispatch(getCurrencyData({ type: formik?.values?.type, branch_id: value }));
    formik.setFieldValue("order_details", [
      {
        currency_code: "",
        product_id: "",
        quantity: "",
        unit_amount:"",
      },
    ]);
    formik.setFieldValue("total_amount", "");
  };

  (function () {
    let fields = [
      "user_id",
      "branch_id",
      "type",
      "currency_code",
      "product_id",
      "quantity",
      "unit_amount",
      "total_amount",
      "citizen_type",
    ];
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "currency_code" ||
        field === "product_id" ||
        field === "quantity" ||
        field === "unit_amount"
      ) {
        return !!formik?.values?.order_details?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    dispatch(
      updateConfig((state) => {
        state.productDetails_percentage =
          (completedFields.length / fields.length) * 100;
      })
    );
    // return
  })();
  const handleAddOrder = () => {
    let { order_details } = formik?.values;
    order_details = [
      ...order_details,
      {
        currency_code: "",
        product_id: "",
        quantity: "",
        unit_amount: "",
      },
    ];
    formik.setFieldValue("order_details", order_details);

    // const newIndex = formik?.values?.order_details?.length;
    // setActiveKey(newIndex?.toString());
  };
  const loadOptions = async (search, loadedOptions, { page }) => {
    const action = await dispatch(getUserSearchData({ search, page }));
    const { options, hasMore } = action.payload || { options: [], hasMore: false };
    return {
      options,
      hasMore,
      additional: { page: page + 1 },
    };
  };
  return {
    formik,
    isEdit,
    // userList,
    basicData,
    orderBasicData,
    orderCreateState,
    getFieldError,
    handleInputSearch,
    handleBranchChange,
    handleAddOrder,
    loadOptions,
  };
};

export default useProductDetails;
