import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
    search: "",
    inputSearch:"",
    status: "",
    createModal: false,
    isEdit: false,
    EditCreate: {
      productDetails: false,
      travelDetails: false,
      deliveryDetails: false,
      documentDetails: false,
    },
    itemId: "",
    orderId:"",
    itemData: {},
    imagePreview: "",
    deleteModal: false,
    clearSelection: false,
    createFilterModal: false,
    formActiveTab: "Product Details",
    orderBasicData: {},
    basic_data_status:"idle",
    documents: [],
    service_type_id:[],
    languages:[],
    currency_data_status:"idle",
    view_data_status:"idle",
    currencyData:{},
    productDetails_percentage:0,
    travelDetails_percentage:0,
    deliveryDetails_percentage:0,
    documentDetails_percentage:0,
    userData: {},
    userStatus: "idle",
    options: [],     // The array of options
    hasMore: true,   // Whether there are more pages to load
    additional: {},
  };
  export const getOrderBasicData = createAsyncThunk(
    "orderCreate/getOrderBasicData",
    async (body, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get("/admin/order/basic/basic-data", body);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const getCurrencyData = createAsyncThunk(
    "orderCreate/getCurrencyData",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(`admin/order/basic/currency-data?type=${params?.type}&branch_id=${params?.branch_id}`);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const getViewData = createAsyncThunk(
    "orderCreate/getViewData",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(`/admin/orders/order-view?order_id=${params?.id}`);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const getUserSearchData = createAsyncThunk(
    "orderCreate/getUserSearchData",
    async ({ search = "", page = 1 }, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(
          `/admin/order/basic/fetch-user?search=${search}&page=${page}&page_size=15`
        );
        const results = response.data.data.data; // Current page data
        const totalCount = response.data.data.total_count; // Total records in database
        const hasMore = page * 15 < totalCount; // Check if there are more records
        return {
          options: results.map(user => ({
            name: user.name,
            _id: user._id,
          })),
          hasMore,
          page,
        };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const orderCreateSlice = createSlice({
  name: "orderCreateSlice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    // basic data
    builder.addCase(getOrderBasicData.pending, (state) => {
      state.basic_data_status = "pending";
    });
    builder.addCase(getOrderBasicData.fulfilled, (state, action) => {
      state.orderBasicData = action.payload.data.data;
      state.basic_data_status = "fulfilled";
    });
    builder.addCase(getOrderBasicData.rejected, (state) => {
      state.basic_data_status = "rejected";
    });
// currency data
    builder.addCase(getCurrencyData.pending, (state) => {
      state.currency_data_status = "pending";
    })
    builder.addCase(getCurrencyData.fulfilled, (state, action) => {
      state.currencyData = action.payload.data.data??[];
      state.currency_data_status = "fulfilled";
    })
    builder.addCase(getCurrencyData.rejected, (state) => {
      state.currency_data_status = "rejected";
    });
// view data
    builder.addCase(getViewData.pending, (state) => {
      state.view_data_status = "pending";
    })
    builder.addCase(getViewData.fulfilled, (state, action) => {
      state.itemData = action.payload.data.data;
      state.view_data_status = "fulfilled";
    })
    builder.addCase(getViewData.rejected, (state) => {
      state.view_data_status = "rejected";
});

//Search user data
builder.addCase(getUserSearchData.pending, state => {
    state.userStatus = "pending";
  })
  builder.addCase(getUserSearchData.fulfilled, (state, action) => {
    state.userData = {
      options: action.payload.options,
      hasMore: action.payload.hasMore,
      page: action.payload.page,
    };
    state.userStatus = "fulfilled";
  })
  builder.addCase(getUserSearchData.rejected, (state, action) => {
    state.userStatus = "rejected";
    state.error = action.payload;
  });

  },
});

export const { updateConfig } = orderCreateSlice.actions;

export default orderCreateSlice.reducer;
