import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { updateConfig } from "../../../../Store/Slices/Expertise/expertiseSlice";
// import { createExpertise, updateExpertise } from "../api";
import { createTravelDetails, updateTravelDetails } from "./api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getViewData, updateConfig } from "../../../../../store/slices/OrderCreate/orderCreateSlice";
import { useEffect } from "react";

const useTravelDetails = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId,orderBasicData,imagePreview,orderId} = useSelector((state) => state.orderCreate);
  const { basicData } = useSelector((state) => state.global);
  const orderCreateState = useSelector((state) => state.orderCreate);
  const minDate = new Date(); // Today
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 60);
  const validationSchema = Yup.object({
    travel_purpose: Yup.string().required("*Please select the travel purpose"),
    travel_date: Yup.string().required("*Please select the travel date"),
    country: Yup.string().required("*Please select the country"),
    loan: Yup.string()
  .test(
    "is-required",
    "*Loan type is required",
    function (value) {
      const { travel_purpose } = this.parent;
      const isEducationOverseas =
        orderBasicData?.travel_purpose_list.find(
          (item) => item?._id === travel_purpose
        )?.name === "Education Overseas";
      // If the condition is not met, validation should pass (not required)
      if (!isEducationOverseas) {
        return true; // Field is not required
      }

      // If it is required, check if the field has a value
      return !!value; // Ensures `value` is not empty
    }
  ),

    
    loan_document: Yup.mixed()
    .test("is-required", "*Loan Document is required", function (value) {
      const { loan ,travel_purpose} = this.parent;
      const isEducationOverseas =
      orderBasicData?.travel_purpose_list.find(
        (item) => item?._id === travel_purpose
      )?.name === "Education Overseas";   
      if ((loan==="1" && isEducationOverseas &&!value && !itemData?.travel_details?.loan_document)) {
        return false;
      }
      return true;
    })
    .test("fileType", "Invalid file format", (value) => {
      if (value) {
        const supportedFormats = ["image/jpeg", "image/png", "image/svg+xml"];
        return supportedFormats.includes(value.type);
      }
      return true;
    })
    .test("fileSize", "Please upload a file less than 2MB", (value) => {
      if (value) {
        return value.size <= 2097152;
      }
      return true;
    }),
  });
  const isURLFormat = (s) => {
    const urlPattern = /^https?:\/\/\S+/;
    return urlPattern.test(s);
  };

  useEffect(() => {
    if (itemData?.travel_details?.loan_document) {
      dispatch(
        updateConfig((state) => {
          state.imagePreview = itemData?.travel_details?.loan_document;
        })
      );
    }
    // eslint-disable-next-line
  }, [itemData?.travel_details?.loan_document]);

  const formik = useFormik({
    initialValues: {
      travel_purpose: itemData?.travel_details?.purpose?.id?? "",
      travel_date: itemData?.travel_details?.date ?? "",  
      country: itemData?.travel_details?.country?.id ?? "",
      loan: itemData?.travel_details?.loan_status != null ? String(itemData?.travel_details?.loan_status) : "",
      loan_document:""
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values,{ setSubmitting }) => {
        let data = {
          travel_purpose: values.travel_purpose,
          travel_date: values?.travel_date,
          country: values?.country,        
          loan: values?.loan,
          loan_document:values?.loan_document,
          travel_declaration:1,
          previous_amount:0,
          order_id:orderId,
        };
        if ((values?.loan_document===""&& orderCreateState?.EditCreate.travelDetails) || values?.loan != 1) {
          delete data.loan_document;
        }
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        formData.append("_method", "PUT");
        createTravelDetails(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Travel details have been added.");
            dispatch(
              updateConfig((state) => {
                state.formActiveTab ="Documents"
                state.imagePreview=""
                state.EditCreate.productDetails= orderCreateState?.EditCreate.productDetails;
                state.EditCreate.travelDetails= true;
                state.EditCreate.deliveryDetails = orderCreateState?.EditCreate.deliveryDetails;
                state.EditCreate.documentDetails = orderCreateState?.EditCreate.documentDetails;
              })
            );
            setSubmitting(false);
            handleView()
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.data;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      }
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleImageChange = (event) => {
    const selectedImage = event?.target?.files[0];
    if (selectedImage) {
      formik.setFieldValue("loan_document", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("loan_document", "");
    formik?.setFieldError("loan_document", "Upload image");
    formik?.setFieldTouched("loan_document", true);
  };
  (function () {
    let fields = [
      "travel_purpose",
      "travel_date",
      "country",
      "loan",
      ...(formik?.values?.loan===1 ? ["loan_document"]:[]),
    ];
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      }else {
        return !!formik?.values[field];
      }
    });
    dispatch(
      updateConfig((state) => {
        state.travelDetails_percentage = (completedFields.length / fields.length) * 100
      })
    );
    // return 
  })()
  const handleNext=()=>{
    formik.handleSubmit();
  }
  const handleView=()=>{
    dispatch(getViewData({ id: orderId }));
  }
  return {
    formik,
    isEdit,
    orderBasicData,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    isURLFormat,
    basicData,
    itemData
  };
};

export default useTravelDetails;
