import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import FileUpload from "../../../../Global/FileUpload";
import useDocuments from "./useDocuments";
import Assets from "../../../../../assets/Assets";

const Documents = ({
  // formik,
  refetch,
  providersState,
   handleClose,
  status,
  setStatus,
}) => {
  const {
    formik,
    isEdit,
    orderBasicData,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    basicData,
    itemData
  } = useDocuments({
    refetch,
    providersState,
    // getFieldError,
    setStatus,
    status,
  });
  const handleCloseModal = () => {};
  const handleAddOrder = () => {
    let { order_details } = formik?.values;
    order_details = [
      ...order_details,
      {
        currency_code: "",
        product_id: "",
        quantity: "",
      },
    ];
    formik.setFieldValue("order_details", order_details);

    // const newIndex = formik?.values?.order_details?.length;
    // setActiveKey(newIndex?.toString());
  };
  return (
    <div className="col">
      {orderBasicData?.document_types?.map((value, index) => (
        <div className="pro-mb-4" key={index}>
          <FileUpload
            formik={formik}
            title={value?.name}
            filePreview={
              value?.id === 1
                ? formik?.values?.passport_front_id
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===1))?.name
                  : ""
                : value?.id === 2
                ? formik?.values?.passport_back_id
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===2))?.name
                  : ""
                : value?.id === 3
                ? formik?.values?.ticket
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===3))?.name
                  : ""
                : value?.id === 4
                ? formik?.values?.visa
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===4))?.name
                  : ""
                : value?.id === 5
                ? formik?.values?.other_document
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===5))?.name
                  : ""
                : value?.id === 6
                ? formik?.values?.pan_card_id
                  ? itemData?.order_documents?.documents?.find((item)=>(item?.id_type===6))?.name
                  : ""
                : ""
            }
            // filePreview={imagePreview}
            handleFileChange={(item) => handleImageChange(item, value?.id)}
            handleFileRemove={() =>
              handleImageRemove(
                value?.id === 1
                  ? formik?.values?.passport_front_id
                  : value?.id === 2
                  ? formik?.values?.passport_back_id
                  : value?.id === 3
                  ? formik?.values?.ticket
                  : value?.id === 4
                  ? formik?.values?.visa
                  : value?.id === 5
                  ? formik?.values?.other_document
                  : value?.id === 6
                  ? formik?.values?.pan_card_id
                  : "",
                value?.id
              )
            }
            handleBlur={(event) => {
              const idToFieldMap = {
                1: "passport_front_id",
                2: "passport_back_id",
                3: "ticket",
                4: "visa",
                5: "other_document",
                6: "pan_card_id",
              };    
              const fieldName = idToFieldMap[value?.id];
              if (fieldName) {
                formik.handleBlur({ target: { name: fieldName } });
              }else {
                return
              }
            }}  
            maxSize="5"  
            getFieldError={getFieldError}
            isError={
              value?.id === 1
                ? formik?.errors?.passport_front_id
                  ? getFieldError("passport_front_id")
                  : ""
                : value?.id === 2
                ? formik?.errors?.passport_back_id
                  ? getFieldError("passport_back_id")
                  : ""
                : value?.id === 3
                ? formik?.errors?.ticket
                  ? getFieldError("ticket")
                  : ""
                : value?.id === 4
                ? formik?.errors?.visa
                  ? getFieldError("visa")
                  : ""
                : value?.id === 5
                ? formik?.errors?.other_document
                  ? getFieldError("other_document")
                  : ""
                : value?.id === 6
                ? formik?.errors?.pan_card_id
                  ? getFieldError("pan_card_id")
                  : ""
                : ""
            }
          />
                <span className="error-custom">
                  {value?.id === 1
                    ? formik?.errors?.passport_front_id
                      ? getFieldError("passport_front_id")
                      : ""
                    : value?.id === 2
                    ? formik?.errors?.passport_back_id
                      ? getFieldError("passport_back_id")
                      : ""
                    : value?.id === 3
                    ? formik?.errors?.ticket
                      ? getFieldError("ticket")
                      : ""
                    : value?.id === 4
                    ? formik?.errors?.visa
                      ? getFieldError("visa")
                      : ""
                    : value?.id === 5
                    ? formik?.errors?.other_document
                      ? getFieldError("other_document")
                      : ""
                    : value?.id === 6
                    ? formik?.errors?.pan_card_id
                      ? getFieldError("pan_card_id")
                      : ""
                    : ""}
                </span>
        </div>
      ))}
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Pan Number"}
          type="text"
          id="pan_number"
          name="pan_number"
          className={`pro-input lg ${getFieldError("pan_number") && " error"}`}
          {...formik.getFieldProps("pan_number")}
          error={getFieldError("pan_number")}
          errorMessage={getFieldError("pan_number")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={()=>handleClose()}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="button"
          className={`pro-btn-primary lg pro-ms-3 pro-px-5 ${formik.isSubmitting ? "loading" : ""}`}
          disabled={formik.isSubmitting}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Documents;
