import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";
import { settings } from "../../../../store/queries/settings";
import { product } from "../../../../store/queries/product";
import { logoutUser } from "./api";
import { roles } from "../../../../store/queries/roles";
import { toast } from "react-toastify";
import { useGetNotificationListDataQuery, useUpdateAllNotificationDataMutation } from "../../../../store/queries/notifications";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeUser = useMemo(
    () => JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found",
    []
  );

  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  // const subAdminState = useSelector((state) => state.subAdmins);
  const notificationState = useSelector((state) => state.notification);
  const [updateNotification] = useUpdateAllNotificationDataMutation();

  const {
    data: notification = {},
    refetch,
    isFetching
  } = useGetNotificationListDataQuery({
    per_page: notificationState.currentPageSize,
    page: notificationState.currentPage,
    // is_read:notificationState.isRead
  });

  useEffect(() => {

    let notificationData = notification?.data?.data?.filter(
      (item) => item.read_at === null
    );
    // if (notificationData?.length > 0) {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.isNewNotification = true;
    //     })
    //   );
    //   // setIsNewNotification(true);
    // }
    if (notificationData?.length > 0) {
      dispatch(
        updateConfig((state) => {
          state.isNewNotification = true;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.isNewNotification = false;
        })
      );
    }
  }, [notification?.data]);

  useEffect(() => {
    if (isProfileViewVisible)
      dispatch(
        updateConfig((state) => {
          state.isProfileViewVisible = false;
          setProfileViewVisible(false);
        })
      );
    //eslint-disable-next-line
  }, [isProfileViewVisible]);

  // const handleReadNotification = (isNewNotification) => {
  //   if (isNewNotification) markNotificationRead().then((response) => {});
  // };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = true;
      })
    );
  };

  const handleCloseProfileView = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = false;
      })
    );
  };

  const handleLogout = () => {
    logoutUser().then((res) => {
      toast.success(res?.data?.message ?? "Logged out succesfully");
    });
    dispatch(product.util.resetApiState());
    dispatch(roles.util.resetApiState());
    dispatch(settings.util.resetApiState());

    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings/appearance");
  };

  const handleProfileRedirect = (userId) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), userId]
      : [userId];

    const newIds = ids.filter((id) => id !== userId).join(",");

    window.open(
      `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    );
  };

  const handleNotificationRedirect = () => {
    navigate("/notifications");

    setTimeout(() => {
      updateNotification().then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.isNewNotification = false;
            })
          );
        }
      });
    }, 1000);
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    activeUser,
    // subAdminState,
    userData: globalState?.searchData?.[activeUser],
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleProfileRedirect,
    handleSettings,
    handleViewProfile,
    handleCloseProfileView,
    setProfileViewVisible,
    // handleReadNotification,
    handleNotificationRedirect,
    notification: notification,
    isNewNotification: globalState.isNewNotification,
    refetch,
    isFetching
  };
};

export default useHeaderActions;
