import React from "react";
import {
  Header,
  Image,
  MultiColumnTable,
  NavGroup,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../pages/Layouts/CommonLayout/commonLayout.module.scss"
import profileStyle from "./userDetailLayout.module.scss"
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";

// import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../assets/Assets";
import HeaderActions from "../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../pages/Layouts/CommonLayout/useCommonLayout";
import useUserDetailLayout from "./useUserDetailLayout";
// import CourseAssign from "../CourseAssign";

const UserDetailLayout = () => {
  const {
    activeProfile,
    isFetching,
    // profileData,
    basicDetails,
    Details,
    // orderSummary,
    userView
  } = useUserDetailLayout();
  const { navigations, globalState } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(userView?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div
                    className={`pro-pt-5 pro-d-flex pro-flex-column pro-h-100`}
                  >
                    <div className="pro-px-4 pro-pb-5">
                      {/* <div className={`pro-border-bottom pro-pb-3 `}>
                        <Link to={`/orders`} className="pro-back-btn">
                          <span className="material-symbols-outlined">
                            {" "}
                            keyboard_arrow_left{" "}
                          </span>
                          {`Back`}
                        </Link>
                        <h3 className={`h3 pro-ttl pro-mb-0 pro-mt-3`}>
                          #{activeProfile}
                        </h3>
                        <p>
                          Branch : <span>{orderSummary?.data?.branch}</span>
                        </p>
                      </div> */}
                      <div className={`pro-py-3 pro-border-bottom pro-mb-4`}>
                      <Link to={`/user`} className="pro-back-btn">
                          <span className="material-symbols-outlined">
                            {" "}
                            keyboard_arrow_left{" "}
                          </span>
                          {`Back`}
                        </Link>
                      <SimpleTextDetails
                        title={userView?.data?.name ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${userView?.data?.profile_name_wrap}`}
                      />
                    </div>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={`${profileStyle.profile}  pro-mb-4 `}>
                        <Image
                          src={`${userView?.data?.profile_image??Assets.MALEPROFILE}`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>
                    </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Basic Details"}
                          data={basicDetails}
                          extraClassNames={profileStyle.table}
                        />
                      </div>
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          <div className={`${profileStyle.main_container} pro-pt-4 pro-ps-5`}>
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default UserDetailLayout;
