import { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useGetOrderSummaryQuery } from "../../../store/queries/orders";
import { useGetUserViewDataQuery } from "../../../store/queries/user";

const useUserDetailLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("userDetails"));
  const { orderID, userID } = useParams();
  const globalState = useSelector((state) => state.global);
  const location = useLocation();
  const activeProfile = orderID ?? sessionStorage.getItem("active");
  const currentFilter = localStorage.getItem("currentFilter");

  const { data: userView, isFetching } = useGetUserViewDataQuery({
    user_id: userID,
  });
  // if (localStorage.getItem("currentFilter") === "sell") {
  //   const indexTravel = navigation?.findIndex(
  //     (item) => item.label === "Travel Details"
  //   );
  //   if (indexTravel !== -1) {
  //     navigation.splice(indexTravel, 1);
  //   }
  //   const indexDocument = navigation?.findIndex(
  //     (item) => item.label === "Document Details"
  //   );
  //   if (indexDocument !== -1) {
  //     navigation?.splice(indexDocument, 1);
  //   }
  // }

  const label = {
    user_id: "User ID",
    name: "Name",
    email: "Email",
    phone: "Phone",
    dob: "DOB",
    country:"Country",
    state:"State",
    district:"District",
  };
  const value = {
    user_id: `${userView?.data?.user_unique_id ?? "Not specified"}` ,
    name: `${userView?.data?.name ?? "Not specified"}`,
    email: `${userView?.data?.email ?? "Not specified"}`,
    phone: `${
        userView?.data?.phone_number
        ? `${userView?.data?.country_code} ${userView?.data?.phone_number}`
        : "Not specified"
    }`,
    country: `${userView?.data?.country?.name ?? "Not specified"}`,   
    state: `${userView?.data?.state?.name ?? "Not specified"}`,
    district: `${userView?.data?.district?.name ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    show,
    activeProfile,
    isFetching,
    printRef,
    // profileData,
    basicDetails,
    showDeleteImageModal,
    userData,
    // orderSummary,
    setShowDeleteImageModal,
    setShow,
    userView,
    // refetch,
  };
};

export default useUserDetailLayout;
