import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useTravelDetails from "./useTravelDetails";
import FileUpload from "../../../../Global/FileUpload";

const TravelDetails = ({
  // formik,
  refetch,
  providersState,
  handleView,
  status,
  setStatus,
}) => {
  const {
    formik,
    isEdit,
    orderBasicData,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    isURLFormat,
    basicData,
    itemData,
  } = useTravelDetails({
    refetch,
    providersState,
    // getFieldError,
    setStatus,
    status,
  });
  const handleCloseModal = () => {};
  const handleAddOrder = () => {
    let { order_details } = formik?.values;
    order_details = [
      ...order_details,
      {
        currency_code: "",
        product_id: "",
        quantity: "",
      },
    ];
    formik.setFieldValue("order_details", order_details);

    // const newIndex = formik?.values?.order_details?.length;
    // setActiveKey(newIndex?.toString());
  };
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label
          htmlFor="travel_purpose"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Select Purpose of Travel
        </label>
        <Select
          id="travel_purpose"
          placeholder={"Select Purpose of Travel"}
          className={`pro-input lg  ${
            getFieldError("travel_purpose") && " error"
          }`}
          classNamePrefix="pro-input"
          name="travel_purpose"
          options={orderBasicData?.travel_purpose_list ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.travel_purpose_list?.find(
            (value) => value?._id === formik?.values?.travel_purpose
          )??""}
          onBlur={formik.handleBlur("travel_purpose")}
          onChange={(value) => {
            formik.setFieldValue("travel_purpose", value?._id ?? "");
            if (
              orderBasicData?.travel_purpose_list.find(
                (item) => item?._id === value?._id
              )?.name === "Education Overseas"
            ) {
              formik.setFieldValue("loan", "1");
            }
          }}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("travel_purpose") && (
          <span className="error-text">{getFieldError("travel_purpose")}</span>
        )}
      </div>
      <div className={`input-wrap ${!formik?.values?.travel_date ? "input-placeholder" : ""}`}>
        <Input
          label={"Select Date of Travel"}
          type="date"
          id="travel_date"
          name="travel_date"
          className={`pro-input lg ${getFieldError("travel_date") && " error"}`}
          {...formik.getFieldProps("travel_date")}
          value={formik?.values?.travel_date}
          error={getFieldError("travel_date")}
          errorMessage={getFieldError("travel_date")}
          min={new Date().toISOString().split("T")[0]} // Today's date
          max={
            new Date(new Date().setDate(new Date().getDate() + 60))
              .toISOString()
              .split("T")[0]
          } // Today's date + 60 days
        />
      </div>
      <div className="pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select Tarvelling to Country
        </label>
        <Select
          id="country"
          placeholder={"Country"}
          className={`pro-input lg  ${getFieldError("country") && " error"}`}
          classNamePrefix="pro-input"
          name="country"
          options={orderBasicData?.countries ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.countries?.find(
            (value) => value?._id === formik?.values?.country
          )}
          onBlur={formik.handleBlur("country")}
          onChange={(value) =>
            formik.setFieldValue("country", value?._id ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("country") && (
          <span className="error-text">{getFieldError("country")}</span>
        )}
      </div>
      {(formik?.values?.travel_purpose
        ? orderBasicData?.travel_purpose_list.find(
            (item) => item?._id === formik?.values?.travel_purpose
          )?.name === "Education Overseas"
        : true) && (
        <div className={`pro-mb-4`}>
          <div className="row">
            <div className="col-auto">
              <div className="pro-check-box radio">
                <input
                  className="pro-check"
                  type="radio"
                  name="radio_type"
                  id="without_loan"
                  checked={formik?.values?.loan === "0"}
                  onChange={() => formik.setFieldValue("loan", "0")}
                />
                <label className="pro-check-label" htmlFor="without_loan">
                  Without Loan
                </label>
              </div>
            </div>
            <div className="col-auto">
              <div className="pro-check-box radio">
                <input
                  className="pro-check"
                  type="radio"
                  name="radio_type"
                  id="loan"
                  checked={formik?.values?.loan === "1"}
                  onChange={() => formik.setFieldValue("loan", "1")}
                  disabled={
                    formik?.values?.travel_purpose &&
                    !orderBasicData?.travel_purpose_list.find(
                      (item) => item?._id === formik?.values?.travel_purpose
                    )?.name === "Education Overseas"
                  }
                />
                <label className="pro-check-label" htmlFor="loan">
                  With Loan
                </label>
              </div>
            </div>
            {getFieldError("loan") && (
              <span className="error-custom">{getFieldError("loan")}</span>
            )}
          </div>
        </div>
      )}
      {formik?.values?.loan === "1" &&
        (formik?.values?.travel_purpose
          ? orderBasicData?.travel_purpose_list.find(
              (item) => item?._id === formik?.values?.travel_purpose
            )?.name === "Education Overseas"
          : true) && (
          <div className="pro-mb-4">
            <FileUpload
              formik={formik}
              title={"Loan Document"}
              filePreview={imagePreview}
              handleFileChange={handleImageChange}
              handleFileRemove={handleImageRemove}
              getFieldError={getFieldError}
              isError={getFieldError("loan_document")}
            />
            {getFieldError("loan_document") && (
              <span className="error-custom">
                {getFieldError("loan_document")}
              </span>
            )}
          </div>
        )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => {
            handleView();
          }}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={`pro-btn-primary lg pro-ms-3 pro-px-5 ${formik.isSubmitting ? "loading" : ""}`}
          disabled={formik.isSubmitting}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default TravelDetails;
