import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getViewData,
  updateConfig,
} from "../../../../store/slices/OrderCreate/orderCreateSlice";
// import { updateConfig as basicUpdateconfig } from "../../../../store/slices/packages/packageSlice";
import { getFormatedDate } from "../../../../utils/functions/table";
import { toast } from "react-toastify";
import ProductDetails from "./ProductDetails";
import TravelDetails from "./TravelDetails";
import Documents from "./Documents";
import DeliveryDetails from "./DeliveryDetails";
import OrderSummary from "./OrderSummary";

const useMultiTabForm = (setShowform, refetch, basicData) => {
  const [moveToTab, setMoveToTab] = useState(false);
  const dispatch = useDispatch();
  const [hideStep, setHideStep] = useState(false);
  const orderCreateState = useSelector((state) => state.orderCreate);
  const {
    editPackage,
    isLoading,
    selectedData,
    formActiveTab,
    productDetails_percentage,
    travelDetails_percentage,
    deliveryDetails_percentage,
    orderId,
    documentDetails_percentage,
  } = useSelector((state) => state.orderCreate);


  let tabs = [
    {
      label: "Product Details",
      completed: productDetails_percentage,
    },
    ...(!hideStep
      ? [
          {
            label: "Travel Details",
            completed: travelDetails_percentage,
          },
          {
            label: "Documents",
            completed: documentDetails_percentage,
          },
        ]
      : []),
    {
      label: "Delivery Details",
      completed: deliveryDetails_percentage,
    },
    {
      label: "Order Summary",
      completed: "not",
    },
  ];

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }

  const handleTabClick = (tab) => {
    // if (tab.label !== "Order Summary") {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.formActiveTab = tab?.label;
    //     })
    //   );

    // }
    if (tab.label === "Product Details"&& orderCreateState?.itemData?.order_current_step>=1) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (
      tab.label === "Travel Details" &&
      orderCreateState.EditCreate.productDetails&&orderCreateState?.itemData?.order_current_step>=2
    ) {

      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (
      tab.label === "Documents" &&
      orderCreateState.EditCreate.travelDetails&&orderCreateState?.itemData?.order_current_step>=3
    ) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (
      (tab.label === "Delivery Details" &&
      orderCreateState.EditCreate.documentDetails&&!hideStep &&orderCreateState?.itemData?.order_current_step>=4)||(tab.label === "Delivery Details" &&
      orderCreateState.EditCreate.productDetails&&hideStep &&orderCreateState?.itemData?.order_current_step>=4)
    ) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (
      (orderCreateState.EditCreate.productDetails && orderCreateState?.itemData?.order_current_step>=4 &&
      orderCreateState.EditCreate.travelDetails &&
      orderCreateState.EditCreate.documentDetails &&
      orderCreateState.EditCreate.deliveryDetails&&!hideStep)||(orderCreateState.EditCreate.productDetails &&
      orderCreateState.EditCreate.deliveryDetails&&hideStep &&orderCreateState?.itemData?.order_current_step>=4) 
    ) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }

    // if (isEdit) {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.formActiveTab = tab?.label;
    //     })
    //   );
    // }
    dispatch(getViewData({ id: orderId }));
  };

  useEffect(() => {
    if (orderCreateState?.itemData?.type===2) {
      setHideStep(true)
    }
    if(orderCreateState?.itemData?.order_current_step){
      dispatch(
        updateConfig((state) => {
          state.productDetails_percentage = orderCreateState?.itemData?.order_current_step>=1?100:0;
          state.travelDetails_percentage = orderCreateState?.itemData?.order_current_step>=2?100:0;
          state.deliveryDetails_percentage = orderCreateState?.itemData?.order_current_step>=3?100:0;
          state.documentDetails_percentage = orderCreateState?.itemData?.order_current_step===4?100:0;
        })
      );
    }
  }, [orderCreateState?.itemData?.type]);

  const handleMoveToErrorTab = () => {
    // const firstErrorTab = Object.keys(formik.errors)[0];
    // if (firstErrorTab === "Product Details") {
    //   setActiveTabByName("BasProduct Detailsic");
    //   return "";
    // }
    // if (firstErrorTab === "Travel Details") {
    //   setActiveTabByName("Travel Details");
    //   return "";
    // }
    // if (firstErrorTab === "Documents") {
    //   setActiveTabByName("Documents");
    //   return "";
    // }

    // if (firstErrorTab && firstErrorTab !== formActiveTab) {
    //   const errorTab = tabsAndFields.find((tab) =>
    //     tab.fields.includes(firstErrorTab)
    //   );

    //   setActiveTabByName(errorTab?.label);
    // }
    setMoveToTab(true);
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = "Product Details";
        state.orderId = "";
        state.isEdit = false;
        state.EditCreate.productDetails = false;
        state.EditCreate.travelDetails = false;
        state.EditCreate.deliveryDetails = false;
        state.EditCreate.documentDetails = false;
      })
    );
    setShowform();
    refetch()
  };
  const renderTabContent = () => {
    switch (formActiveTab) {
      case "Product Details":
        return (
          <>
            <ProductDetails
              // formik={formik}
              tabs={tabs}
              handleClose={handleClose}
              selectedItemsDetails={editPackage}
              selectedData={selectedData}
              setHideStep={setHideStep}
              hideStep={hideStep}
            />
          </>
        );
      case "Travel Details":
        return (
          <TravelDetails
            // formik={formik}
            tabs={tabs}
            handleClose={handleClose}
            selectedItemsDetails={editPackage}
          />
        );

      case "Documents":
        return (
          <Documents
            // formik={formik}
            tabs={tabs}
            handleClose={handleClose}
            isLoading={isLoading}
            checkError={handleMoveToErrorTab}
          />
        );
      case "Delivery Details":
        return (
          <DeliveryDetails
            // formik={formik}
            tabs={tabs}
            handleClose={handleClose}
            isLoading={isLoading}
            checkError={handleMoveToErrorTab}
            hideStep={hideStep}
          />
        );
      case "Order Summary":
        return (
          <OrderSummary
            // formik={formik}
            tabs={tabs}
            handleClose={handleClose}
            isLoading={isLoading}
            checkError={handleMoveToErrorTab}
          />
        );
      default:
        return null;
    }
  };

  return {
    tabs,
    isLoading,
    activeTab: formActiveTab,
    handleTabClick,
    // formik,
    renderTabContent,
    orderCreateState,
    editPackage,
  };
};

export default useMultiTabForm;
