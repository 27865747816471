import React from "react";
import useOrderDetails from "./useOrderDetails";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "./orderDetails.module.scss";
const OrderDetails = ({
  formik,
  itmIndex,
  getFieldError,
  basicData,
  handleOption,
  currencyData,
}) => {
  const { handleRemoveField, setIndex, index, handleOptions } = useOrderDetails(
    { formik, basicData, currencyData }
  );
  return (
    <div id={itmIndex} className={`${Style.root}`}>
      {formik.values.order_details?.length > 1 && (
        <div className={`${Style.delete_btn}`}>
          <span
            className="material-symbols-outlined x2"
            onClick={() => handleRemoveField(itmIndex)}
          >
            delete
          </span>
        </div>
      )}
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor={`Currency${itmIndex}`}
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Select Currency
        </label>
        <Select
          placeholder={"Select Currency"}
          classNamePrefix="pro-input"
          options={handleOptions() ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          menuPlacement="auto"
          isClearable
          id={`Currency${itmIndex}`}
          name={`order_details.${itmIndex}.currency_code`}
          className={`pro-input lg ${
            formik?.errors?.order_details?.[itmIndex]?.currency_code &&
            formik.touched?.order_details?.[itmIndex]?.currency_code &&
            "error"
          }`}
          value={
            currencyData?.currency_list?.find(
              (e) =>
                e?.currency_code ===
                formik?.values?.order_details?.[itmIndex]?.currency_code
            ) ?? ""
          }
          onBlur={formik.handleBlur(`order_details.${itmIndex}.currency_code`)}
          onChange={(value) => {
            const selectedIndex = currencyData?.currency_list?.findIndex(
              (option) =>
                option?.currency_code === value?.currency_code &&
                value?.currency_code
            );
            setIndex(selectedIndex);
            formik.setFieldValue(
              `order_details.${itmIndex}.currency_code`,
              value?.currency_code ?? ""
            );
            formik.setFieldValue(`order_details.${itmIndex}.product_id`, "");
            formik.setFieldValue(`order_details.${itmIndex}.quantity`, "");
          }}
        />
        {formik?.touched?.order_details?.[itmIndex]?.currency_code &&
          formik?.errors?.order_details?.[itmIndex]?.currency_code && (
            <span className="error-text">
              {formik?.errors?.order_details?.[itmIndex]?.currency_code}
            </span>
          )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor={`Product${itmIndex}`}
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Select Product
        </label>
        <Select
          placeholder={"Select Product"}
          classNamePrefix="pro-input"
          options={currencyData?.currency_list?.[index]?.products ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          menuPlacement="auto"
          isClearable
          id={`Product${itmIndex}`}
          name={`order_details.${itmIndex}.product_id`}
          className={`pro-input lg ${
            formik.errors.order_details?.[itmIndex]?.product_id &&
            formik.touched?.order_details?.[itmIndex]?.product_id &&
            "error"
          }`}
          value={
            currencyData?.currency_list
              ?.find(
                (item) =>
                  item?.currency_code ===
                  formik?.values?.order_details?.[itmIndex]?.currency_code
              )
              ?.products?.find(
                (e) =>
                  e?.id ===
                  formik?.values?.order_details?.[itmIndex]?.product_id
              ) ?? ""
          }
          // value={formik.values.order_details?.[itmIndex]?.product_id || []}
          onBlur={formik.handleBlur(`order_details.${itmIndex}.product_id`)}
          onChange={(value) => {
            formik.setFieldValue(
              `order_details.${itmIndex}.product_id`,
              value?.id
            );
            formik.setFieldValue(`order_details.${itmIndex}.quantity`, "");
            formik.setFieldValue(`order_details.${itmIndex}.unit_amount`, "");
            formik.setFieldValue(`total_amount`, "");
          }}
        />
        {formik.touched.order_details?.[itmIndex]?.product_id &&
          formik.errors.order_details?.[itmIndex]?.product_id && (
            <span className="error-text">
              {formik.errors.order_details?.[itmIndex]?.product_id}
            </span>
          )}
      </div>
      <div className="input-wrap">
        <Input
          label={`
            Forex Quantity 
            ${
              formik?.values?.order_details?.[itmIndex]?.currency_code &&
              `(No need of Multiple of ${
                currencyData?.currency_list?.find(
                  (e) =>
                    e?.currency_code ===
                    formik?.values?.order_details?.[itmIndex]?.currency_code
                )?.forex_quantity_limit ?? ""
              })`
            }
            `}
          placeholder={"Forex Quantity"}
          type="text"
          maxLength={6}
          onKeyDown={(e) => {
            const regex = /^[0-9]$/;
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Delete",
            ];
            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          id={`ForexQuantity${itmIndex}`}
          name={`order_details.${itmIndex}.quantity`}
          className={`pro-input lg ${
            formik.errors.order_details?.[itmIndex]?.quantity &&
            formik.touched?.order_details?.[itmIndex]?.quantity &&
            "error"
          }`}
          value={formik.values.order_details?.[itmIndex]?.quantity || []}
          onBlur={formik.handleBlur(`order_details.${itmIndex}.quantity`)}
          onChange={(e) => {
            const quantity = Number(e.target.value) || 0; // Ensure quantity is a number
            formik.setFieldValue(
              `order_details.${itmIndex}.quantity`,
              quantity
            );

            const product = currencyData?.currency_list
              ?.find(
                (e) =>
                  e?.currency_code ===
                  formik?.values?.order_details?.[itmIndex]?.currency_code
              )
              ?.products?.find(
                (e) =>
                  e?.id ===
                  formik?.values?.order_details?.[itmIndex]?.product_id
              );

            const totalAmount = Number(product?.total_amount) || 0; // Ensure total_amount is a number
            const sum = parseFloat((quantity * totalAmount).toFixed(2)); // Ensure sum is rounded to 2 decimal places

            // Update unit_amount
            formik.setFieldValue(`order_details.${itmIndex}.unit_amount`, sum);
          }}
          error={
            formik.touched.order_details?.[itmIndex]?.quantity &&
            formik.errors.order_details?.[itmIndex]?.quantity && (
              <span className="error-text">
                {formik.errors.order_details?.[itmIndex]?.quantity}
              </span>
            )
          }
          errorMessage={
            formik.touched.order_details?.[itmIndex]?.quantity &&
            formik.errors.order_details?.[itmIndex]?.quantity && (
              <span className="error-text">
                {formik.errors.order_details?.[itmIndex]?.quantity}
              </span>
            )
          }
        />
      </div>
      <div className="input-wrap">
        <Input
          label={`
            Amount in INR ${
              formik?.values?.order_details?.[itmIndex]?.product_id &&
              `(1 ${formik?.values?.order_details?.[itmIndex]?.currency_code} =
            ${
              currencyData?.currency_list
                ?.find(
                  (e) =>
                    e?.currency_code ===
                    formik?.values?.order_details?.[itmIndex]?.currency_code
                )
                ?.products?.find(
                  (e) =>
                    e?.id ===
                    formik?.values?.order_details?.[itmIndex]?.product_id
                )?.total_amount ?? ""
            })`
            }
            `}
          placeholder={"Amount in INR"}
          type="text"
          maxLength={6}
          onKeyDown={(e) => {
            const regex = /^[0-9]$/;
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Delete",
            ];
            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          id={`Amount${itmIndex}`}
          name={`order_details.${itmIndex}.unit_amount`}
          className={`pro-input pro-md-100 lg ${
            formik.errors.order_details?.[itmIndex]?.unit_amount &&
            formik.touched?.order_details?.[itmIndex]?.unit_amount &&
            "error"
          }`}
          value={formik.values.order_details?.[itmIndex]?.unit_amount || ""}
          onBlur={formik.handleBlur(`order_details.${itmIndex}.unit_amount`)}
          onChange={(e) => {
            // Set the unit_amount field value
            const unitAmount = Number(e.target.value) || 0; // Ensure it's a valid number
            formik.setFieldValue(
              `order_details.${itmIndex}.unit_amount`,
              unitAmount
            );
          }}
          error={
            formik.touched.order_details?.[itmIndex]?.unit_amount &&
            formik.errors.order_details?.[itmIndex]?.unit_amount && (
              <span className="error-text">
                {formik.errors.order_details?.[itmIndex]?.unit_amount}
              </span>
            )
          }
          errorMessage={
            formik.touched.order_details?.[itmIndex]?.unit_amount &&
            formik.errors.order_details?.[itmIndex]?.unit_amount && (
              <span className="error-text">
                {formik.errors.order_details?.[itmIndex]?.unit_amount}
              </span>
            )
          }
          disabled={true}
        />
      </div>
    </div>
  );
};

export default OrderDetails;
