import Style from "./fileUpload.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
const FileUpload = ({
  title,
  filePreview,
  isError,
  errorMsg,
  handleFileChange,
  handleFileRemove,
  maxSize = "2",
  status = "",
  handleBlur = () => {},
}) => {

  return (
    <div className={`${Style.root} pro-d-flex pro-flex-column`}>
      <label htmlFor={title} className="pro-font-sm pro-mb-1 pro-fw-medium">
        {title ?? ""}
      </label>
      {status === "pending" ? (
        <div className={`${Style.box_wrap}`}>
          <div className={`${Style.input_box}`}>
            <div className="app-loading"></div>
          </div>
        </div>
      ) : (
        <>
        <div className={`${Style.box_wrap}`}>
          <input
            type="file"
            multiple={true}
            accept=".jpeg,.jpg ,.png ,.svg,.pdf"
            className={`pro-input ${isError && `error ${Style.error} `}`}
            id={title ?? ""}
            onBlur={handleBlur}
            onChange={(e) => handleFileChange(e)}
          />
          <span
            className={`${Style.input_box} pro-d-flex pro-flex-column pro-justify-center pro-items-center pro-text-center`}
          >
            <span
              className={`pro-d-flex pro-items-center pro-font-sm pro-fw-medium ${Style.text}`}
            >
              <span className="material-symbols-outlined pro-me-1">
                cloud_upload
              </span>
              Drop file to attach or<span className="pro-ms-1">browse</span>
            </span>
            <span className="pro-font-xs">
              jpg or png - Max file size {maxSize}MB
            </span>
          </span>
          {filePreview && (
            <span className={`${Style.img_wrap}`}>
              <Image
                src={filePreview}
                width={48}
                height={48}
                alt={`name`}
                propStyle={{ root: Style.img }}
              />
              <span
                onClick={handleFileRemove}
                className={`${Style.delete_btn} pro-rounded-circle pro-d-flex pro-justify-center pro-items-center `}
              >
                <span className="material-symbols-outlined"> delete </span>
              </span>
            </span>
          )}
          {/* <span className="error-text">{formik.errors.logo}</span> */}
        </div>
        <>
        {errorMsg && isError && (
            <span className="error-custom">{errorMsg}</span>
          )}</>
          </>
      )}
    </div>
  );
};

export default FileUpload;
