import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { updateConfig } from "../../../../Store/Slices/Expertise/expertiseSlice";
// import { createExpertise, updateExpertise } from "../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getViewData, updateConfig } from "../../../../../store/slices/OrderCreate/orderCreateSlice";
import { createDeliveryDetails, pincodeVerify } from "./api";
import { useEffect, useState } from "react";

const useDeliveryDetails = ({ refetch,hideStep}) => {
  const dispatch = useDispatch();
  const [pinMessage, setPinMessage] = useState("");
  const [handleCharge, setHandleCharge] = useState("");
  const [hideDetails, setHideDetails] = useState(false);
  const { isEdit, itemData,orderBasicData,orderId} = useSelector((state) => state.orderCreate);
  const { basicData } = useSelector((state) => state.global);
  const orderCreateState = useSelector((state) => state.orderCreate);
  const baseSchema = {
    delivery_type: Yup.string().required("*Delivery type is required"),
    pincode: Yup.string()
      .required("*Pincode is required")
      .max(6, "Pincode must be at most 6 characters long")
      .matches(/^\d{6}$/, 'Pincode must be exactly 6 digits'),
    address: Yup.string().required("*Address is required").min(4, "The address must be at least 4 characters."),
    country: Yup.string().required("*Country is required"),
    state: Yup.string().required("*State is required"),
    district: Yup.string().required("*District is required"),
    payment_method: Yup.string().required("*Payment Method is required"),
  };
  const baseSchemaBranch = {
    delivery_type: Yup.string().required("*Delivery type is required"),
    payment_method: Yup.string().required("*Payment Method is required")
  };
  const paymentOption=[{
    id:2,
    name:"Pay to Branch"
  },
  {
    id:3,
    name:"Pay Online"
  }
]
  const validationSchema = Yup.object({
    ...(hideStep ? { payment_method: Yup.string().required("*Payment Method is required") } : hideDetails?{...baseSchemaBranch,}:{...baseSchema,}
  ),
  });
  useEffect(() => {
    if (itemData?.delivery_details?.delivery_type?.id) {
      setHideDetails(itemData?.type===1?(itemData?.delivery_details?.delivery_type?.id===1 ? false:true): true)
    } 
    // eslint-disable-next-line
  }, [itemData?.delivery_details?.delivery_type?.id]);
  const countryIndia=orderBasicData?.countries?.find(
    (value) => value?.name === "India"
  )
  const formik = useFormik({
    initialValues: {
      delivery_type: itemData?.type===1?(itemData?.delivery_details?.delivery_type?.id ?? 1): 2,
      pincode: itemData?.delivery_details?.pincode ?? "",
      address:itemData?.delivery_details?.address ?? "",  
      country: itemData?.delivery_details?.country?.id ?? (countryIndia?._id??""),
      state: itemData?.delivery_details?.state?.id ?? "",
      district: itemData?.delivery_details?.district?.id ?? "",
      payment_method: itemData?.payment_mode ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
        let data = {
          order_id:orderId,
          delivery_type: values.delivery_type,
          pincode: values?.pincode,
          address: values?.address,
          country: values.country,
          state: values?.state,
          district: values?.district,
          payment_method: values.payment_method,
          terms_accepted: "1",
        };
        createDeliveryDetails(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Your order has been successfully created.");
            dispatch(
              updateConfig((state) => {
                state.formActiveTab ="Order Summary"
              })
            );
            dispatch(
              updateConfig((state) => {
                state.formActiveTab ="Order Summary"
                state.EditCreate.productDetails= orderCreateState?.EditCreate.productDetails;
                state.EditCreate.travelDetails= orderCreateState?.EditCreate.productDetails;
                state.EditCreate.deliveryDetails = true;
                state.EditCreate.documentDetails = orderCreateState?.EditCreate.documentDetails;
              })
            );
            setSubmitting(false);
            dispatch(getViewData({ id: orderId }));
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.data;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      }
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  (function () {
    let fields = [
      "delivery_type",
      "pincode",
      "address",
      "country",
      "state",
      "district",
      "payment_method",
    ];
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      }else {
        return !!formik?.values[field];
      }
    });
    dispatch(
      updateConfig((state) => {
        state.deliveryDetails_percentage = (completedFields.length / fields.length) * 100
      })
    );
    // return 
  })()

  const handleNext=()=>{
    formik.handleSubmit();
  }
  const handlePincodeChange=(e)=>{
    let value=e.target?.value
    formik.handleChange(e);
    setHandleCharge("")
    setPinMessage("")
    if(value?.length===6){
      let data = {
        pincode: value,
        order_id: orderId,
      };
      pincodeVerify(data).then((response) => {
        if (response?.status===200) {
          setHandleCharge(response?.data?.data?.handling_charge)
        } else if (!response?.success) {
          let errors = response?.message;
          setPinMessage(errors)
        } else if (response?.success === false) {
          let errors = response?.data;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
        } else {
          toast.error("Something went wrong");
        }
      });
    }
    // setTimeout(() => {
    //   setPinMessage("")
    // }, 15000); 

  }
  return {
    formik,
    isEdit,
    itemData,
    orderBasicData,
    getFieldError,
    basicData,
    pinMessage,
    handleNext,
    handlePincodeChange,
    setHideDetails,
    handleCharge,
    hideDetails,
    paymentOption
  };
};

export default useDeliveryDetails;
